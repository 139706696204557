import "core-js/modules/es6.array.fill";

/* eslint-disable import/max-dependencies */
import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Div from "../../../packages/Div/src";
import Flex from "../../../packages/Flex/src";
import Link from "../../../packages/Link/src";
import Breadcrumbs from "../../../packages/Breadcrumbs/src";
import Table from "../../../packages/Table/src";
import Image from "../../../packages/Image/src";
import Icon from "../../../packages/Icon/src";
import Autocomplete from "../../../packages/Autocomplete/src";
import { Grid, GridItem } from "../../../packages/Grid/src";
import { Heading, P } from "../../../packages/Typography/src";

var DataSourcePagePattern = function DataSourcePagePattern() {
  return React.createElement(PatternLayout, null, React.createElement(Div, {
    bg: "grey.50"
  }, React.createElement(Container, {
    size: "md",
    py: "3rem"
  }, React.createElement(Grid, {
    alignItems: "center"
  }, React.createElement(GridItem, {
    gridColumn: ["span 12", "span 6"]
  }, React.createElement(Breadcrumbs, {
    links: [{
      key: 1,
      href: "/",
      label: "Home"
    }, {
      key: 2,
      href: "/patterns",
      label: "Data sources"
    }, {
      key: 3,
      href: "/patterns/data-source-page",
      label: "World Bank"
    }]
  }), React.createElement(Heading, {
    as: "h1",
    mt: "1.5rem",
    mb: "0"
  }, "World Bank"), React.createElement(P, {
    size: "lg",
    muted: true,
    mt: "0.75rem",
    mb: "1.5rem"
  }, "The World Bank is an international financial institution that provides loans and grants to the governments of poorer countries for the purpose of pursuing capital projects. "), React.createElement(Flex, null, React.createElement(Link, {
    variant: "standalone",
    href: "/",
    mr: "1.5rem"
  }, React.createElement(Flex, {
    alignItems: "center"
  }, "Go to site", React.createElement(Icon, {
    name: "external-link",
    ml: "0.5rem",
    size: "1.25rem"
  }))), React.createElement(Link, {
    variant: "standalone",
    href: "/"
  }, React.createElement(Flex, {
    alignItems: "center"
  }, "Data on Github", React.createElement(Icon, {
    name: "github",
    ml: "0.5rem",
    size: "1.25rem"
  }))))), React.createElement(GridItem, {
    gridColumn: ["span 12", "span 6", "8 / span 4"]
  }, React.createElement(Flex, {
    justifyContent: "center",
    py: "2.5rem"
  }, React.createElement(Image, {
    src: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/The_World_Bank_logo.svg/2880px-The_World_Bank_logo.svg.png"
  })))))), React.createElement(Container, {
    size: "md",
    my: "5rem"
  }, React.createElement(Div, {
    width: ["100%", "30%"],
    mb: "0.75rem"
  }, React.createElement(Autocomplete, {
    options: [{
      value: "1",
      label: "Agricultural irrigated land"
    }, {
      value: "2",
      label: "Arable land"
    }, {
      value: "3",
      label: "Agriculture, forestry, and fishing, value added"
    }, {
      value: "4",
      label: "Rural population"
    }],
    placeholder: "Search for an indicator"
  })), React.createElement(Table, {
    data: new Array(25).fill().map(function () {
      return {
        indicator: "Rural population",
        period: "1941 - 2017",
        dimensions: "Countries",
        topics: "Economics",
        updated: "Yesterday",
        action: React.createElement(Link, {
          variant: "standalone"
        }, React.createElement(Flex, {
          alignItems: "center"
        }, "Explore", React.createElement(Icon, {
          name: "chevron_right",
          ml: "0.5rem",
          size: "1.25rem"
        })))
      };
    }),
    columns: [{
      Header: "Indicator",
      accessor: "indicator"
    }, {
      Header: "Period",
      accessor: "period"
    }, {
      Header: "Dimensions",
      accessor: "dimensions"
    }, {
      Header: "Topics",
      accessor: "topics"
    }, {
      Header: "Updated",
      accessor: "updated"
    }, {
      Header: "",
      accessor: "action"
    }]
  })), React.createElement(Div, {
    bg: "primary"
  }, React.createElement(Container, {
    size: "md",
    py: "5rem"
  }, React.createElement(Grid, null, React.createElement(GridItem, {
    gridColumn: ["span 12", "span 4"]
  }, React.createElement(P, {
    size: "xl",
    fontWeight: 700,
    color: "white",
    mb: "0.75rem",
    mt: "0"
  }, "500+ indicators"), React.createElement(P, {
    size: "md",
    muted: true,
    color: "white",
    my: "0"
  }, "available on Datastory")), React.createElement(GridItem, {
    gridColumn: ["span 12", "span 4"]
  }, React.createElement(P, {
    size: "xl",
    fontWeight: 700,
    color: "white",
    mb: "0.75rem",
    mt: "0"
  }, "Covers 3 topics"), React.createElement(P, {
    size: "md",
    muted: true,
    color: "white",
    my: "0"
  }, "Economy / Global development / Education")), React.createElement(GridItem, {
    gridColumn: ["span 12", "span 4"]
  }, React.createElement(P, {
    size: "xl",
    fontWeight: 700,
    color: "white",
    mb: "0.75rem",
    mt: "0"
  }, "Available in 4 languages"), React.createElement(P, {
    size: "md",
    muted: true,
    color: "white",
    my: "0"
  }, "English / French / Arabic / Spanish"))))));
};

export default DataSourcePagePattern;